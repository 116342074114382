import { ExpandableCard } from '@humanitec/ui-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EnvironmentWorkloads from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/EnvironmentWorkloads/EnvironmentWorkloads';
import ResourceDependencyGraph from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/ResourceDependencyGraph/ResourceDependencyGraph';
import SharedResources from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/SharedResources/SharedResources';
import DeploymentCardEmptyState from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/components/DeploymentCardEmptyState';
import DeploymentCard from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/DeploymentCard';
import useDeploymentsListQuery from '@src/hooks/react-query/environments/queries/useDeploymentsListQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useResourceDependencyGraphQuery from '@src/hooks/react-query/resources/queries/useResourceDependencyGraphQuery';
import { useDeploymentSetStore } from '@src/hooks/zustand/useDeploymentSetStore';

const EnvStatus = () => {
  // React Query
  const { data: environment } = useEnvironmentQuery();
  const { data: deployments } = useDeploymentsListQuery();

  const deployment = useMemo(() => {
    const lastDeployment = deployments?.find(
      (deploy) => deploy.id === environment?.last_deploy?.id
    );
    if (lastDeployment) {
      return lastDeployment;
    }
  }, [environment?.last_deploy?.id, deployments]);

  const { data: resourceDependencyGraph } = useResourceDependencyGraphQuery(
    deployment?.dependency_graph_id
  );

  // Zustand store
  const { setCurrentDeploymentSetId } = useDeploymentSetStore();

  const { t } = useTranslation('viewEnvironment');
  const { t: tCommon } = useTranslation();
  const envStatusTranslations = t('STATUS');
  const resourceDependencyGraphTranslations = tCommon('RESOURCE_DEPENDENCY_GRAPH');

  const lastPastDeploymentId = useMemo(
    () => deployments?.filter((deploy) => deploy.id !== environment?.last_deploy?.id)[0]?.id,
    [environment?.last_deploy?.id, deployments]
  );

  useEffect(() => {
    // set the current deployment set id to undefined, because on this screen we show the workloads
    // and shared resources from the deployment set of the last deployment
    setCurrentDeploymentSetId(undefined);
  }, [setCurrentDeploymentSetId]);

  return (
    <>
      {deployment ? (
        <>
          <DeploymentCard
            cardStyle={'transparent'}
            deploy={deployment}
            isActiveDeployment
            lastPastDeploymentId={lastPastDeploymentId}
          />
          <ExpandableCard
            id={'resource-graph'}
            expandedByDefault={resourceDependencyGraph && resourceDependencyGraph?.nodes.length > 0}
            allowExpandedUpdateAfterFirstRender
            headerContent={
              resourceDependencyGraph?.nodes.length
                ? `${resourceDependencyGraph?.nodes.length} ${envStatusTranslations.CONNECTED_RESOURCES}`
                : resourceDependencyGraphTranslations.NO_GRAPH_AVAILABLE
            }
            cardStyle={'transparent'}
            className={'mb-md'}
            content={
              <ResourceDependencyGraph
                customResourceDependencyGraph={resourceDependencyGraph}
                customDeployment={deployment}
                hideContainerBorder
              />
            }
          />
          <ExpandableCard
            id={'workloads'}
            expandedByDefault
            headerContent={envStatusTranslations.WORKLOADS}
            cardStyle={'transparent'}
            className={'mb-md'}
            content={<EnvironmentWorkloads customDeployment={deployment} hidePauseWarning />}
          />
          <ExpandableCard
            id={'shared-resources'}
            expandedByDefault
            headerContent={envStatusTranslations.SHARED_RESOURCES}
            cardStyle={'transparent'}
            content={<SharedResources inEnvStatusTab />}
          />
        </>
      ) : (
        <DeploymentCardEmptyState />
      )}
    </>
  );
};

export default EnvStatus;
