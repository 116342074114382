import { UIMatch } from '@remix-run/router';
import { useMemo } from 'react';
import { useMatch, useMatches, useParams, useSearchParams } from 'react-router-dom';

import { MatchParams, useDeployOrDeltaSegment } from '@src/models/routing';
import { isCollectionFeature, WorkloadFeatureDefinition } from '@src/models/workload-profile-v2';
import { RouteHandle } from '@src/react-router-types';

import useApplicationQuery from './react-query/applications/queries/useApplicationQuery';
import useDeploymentDeltaQuery from './react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useDeploymentQuery from './react-query/environments/queries/useDeploymentQuery';
import useEnvironmentQuery from './react-query/environments/queries/useEnvironmentQuery';
import usePipelineRunDetailsQuery from './react-query/pipeline-runs/queries/usePipelineRunDetailsQuery';
import usePipelineDetailsQuery from './react-query/pipelines/queries/usePipelineDetailsQuery';
import useUserDetailQuery from './react-query/user/useUserDetailQuery';
import useWorkloadProfileLatestQuery from './react-query/workload-profiles/queries/useWorkloadProfileLatestQuery';
import { useGetDeploymentSetWithDeltaUpdates } from './useGetDeploymentSetWithDeltaUpdates';

export interface BreadcrumbItem {
  name?: string;
  label?: string;
  pathname?: string;
  labelAsTitle?: boolean;
}
const useBreadcrumbs = () => {
  const { orgId, appId, envId, moduleId, pipelineId, pipelineRunId, deltaId, deployId, userId } =
    useParams<keyof MatchParams>() as MatchParams;

  const { deployOrDeltaSegment } = useDeployOrDeltaSegment();

  const [search] = useSearchParams();
  const workloadMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/workloads/:moduleId/*'
  );
  const matches: UIMatch[] = useMatches();

  // Hooks
  const deploymentSetWithDeltaUpdates = useGetDeploymentSetWithDeltaUpdates();

  // React Query
  const { data: delta } = useDeploymentDeltaQuery({
    orgId,
    appId,
    deltaId,
  });
  const { data: deploy } = useDeploymentQuery(
    deployOrDeltaSegment === 'deploys' ? { orgId, appId, envId, deploymentId: deployId } : undefined
  );

  const { data: environment } = useEnvironmentQuery({
    orgId,
    appId,
    envId,
  });
  const { data: pipelineRun } = usePipelineRunDetailsQuery({
    id: pipelineRunId || search.get('run') || undefined,
  });

  const workload = deploymentSetWithDeltaUpdates?.modules[moduleId];

  const { data: workloadProfile } = useWorkloadProfileLatestQuery(workload?.profile);

  const { data: app } = useApplicationQuery({ appId });
  const { data: userDetail } = useUserDetailQuery({ userId });
  const { data: pipeline } = usePipelineDetailsQuery({ id: pipelineId, appId });

  /**
   * get the crumbs from the route handles
   */
  const crumbs = matches
    .filter((match) => Boolean((match.handle as RouteHandle)?.crumbs))
    .flatMap((match) =>
      (match.handle as RouteHandle)?.crumbs(match, {
        userDetail,
        app,
        pipeline,
        pipelineRun,
        deploy,
        env: environment,
        delta,
      })
    );

  /**
   * Generate breadcrumbs based on workload profile structure & available data.
   */
  const workloadProfileCrumbs = useMemo(() => {
    const breadcrumbArray: BreadcrumbItem[] = [];
    if (deployId || (deltaId && moduleId)) {
      const segments = workloadMatch?.params['*']?.split('/');

      let awaitingCollectionId = false;

      let currentFeatures: Record<string, WorkloadFeatureDefinition> | undefined =
        workloadProfile?.spec_definition?.properties;

      let currentCrumbLabel = '';
      let currentCrumbName = '';

      segments?.forEach((segment, index) => {
        const currentFeatureSegment = currentFeatures?.[segment];
        if (!awaitingCollectionId && isCollectionFeature(currentFeatureSegment)) {
          // is collection. on next iteration, look for the collection id
          currentCrumbLabel = currentFeatureSegment.title || segment;
          awaitingCollectionId = true;
          currentFeatures = currentFeatureSegment.properties;
        } else if (awaitingCollectionId && !isCollectionFeature(currentFeatureSegment)) {
          currentCrumbName = segment;
          awaitingCollectionId = false;
        }
        const currentSegments = segments.slice(0, index + 1);
        if (currentCrumbLabel && currentCrumbName) {
          breadcrumbArray.push({
            label: currentCrumbLabel,
            name: currentCrumbName,
            pathname: `${workloadMatch?.pathnameBase}/${currentSegments.join('/')}`,
          });
          currentCrumbName = '';
          currentCrumbLabel = '';
        }
      });
    }
    return breadcrumbArray;
  }, [
    deployId,
    deltaId,
    moduleId,
    workloadMatch?.params,
    workloadMatch?.pathnameBase,
    workloadProfile?.spec_definition?.properties,
  ]);

  return [...crumbs, ...workloadProfileCrumbs];
};

export default useBreadcrumbs;
