import React, { useEffect } from 'react';
import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  useLocation,
  useParams,
} from 'react-router-dom';

import AppRoot from '@src/AppRoot';
import FormPlayground from '@src/components/shared/DynamicForm/FormPlayground/FormPlayground';
import ErrorPage from '@src/components/shared/ErrorPage/ErrorPage';
import PrivateRoute from '@src/components/shared/PrivateRoute';
import { PrivateRouteForOrgRole } from '@src/components/shared/PrivateRouteForOrgRole';
import PublicRoute from '@src/components/shared/PublicRoute/PublicRoute';
import RenderViewWorkload from '@src/components/shared/ViewWorkloadProfile/RenderViewWorkload';
import WorkloadProfilePlayground from '@src/components/shared/ViewWorkloadProfile/WorkloadProfilePlayground';
import Auth from '@src/containers/Auth/Auth';
import AcceptInvite from '@src/containers/Auth/containers/AcceptInvite/AcceptInvite';
import AccountDetails from '@src/containers/Auth/containers/AccountDetails/AccountDetails';
import CreateOrganization from '@src/containers/Auth/containers/CreateOrganization/CreateOrganization';
import GithubLogin from '@src/containers/Auth/containers/GithubLogin/GithubLogin';
import { GithubLoginInvite } from '@src/containers/Auth/containers/GithubLoginInvite/GithubLoginInvite';
import GithubRegister from '@src/containers/Auth/containers/GithubRegister/GithubRegister';
import { GithubRegisterInvite } from '@src/containers/Auth/containers/GithubRegisterInvite/GithubRegisterInvite';
import GithubRegisterMVP from '@src/containers/Auth/containers/GithubRegisterMVP/GithubRegisterMVP';
import Login from '@src/containers/Auth/containers/Login/Login';
import { SignUp } from '@src/containers/Auth/containers/SignUp/SignUp';
import { SSOLogin } from '@src/containers/Auth/containers/SSOLogin/SSOLogin';
import Device from '@src/containers/Device';
import Accounts from '@src/containers/Orgs/Accounts/Accounts';
import App from '@src/containers/Orgs/Apps/containers/App/App';
import DeployedValues from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/DeployedValues/DeployedValues';
import EnvironmentWorkloads from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/EnvironmentWorkloads/EnvironmentWorkloads';
import ResourceDependencyGraph from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/ResourceDependencyGraph/ResourceDependencyGraph';
import SharedResources from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/SharedResources/SharedResources';
import ViewDeploymentAndDeltaCommon from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/DeploymentAndDeltaCommon';
import AppMembers from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/AppMembers/AppMembers';
import DeleteApplication from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/DeleteApplication/DeleteApplication';
import Environments from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Environments/Environments';
import { DeletePipeline } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/DeletePipeline/DeletePipeline';
import PipelineDetails from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineDetails/PipelineDetails';
import { PipelineMatchingCriteria } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineDetails/PipelineMatchingCriteria/PipelineMatchingCriteria';
import PipelinesList from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelinesList/PipelinesList';
import PipelinesYaml from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelinesYaml/PipelinesYaml';
import PipelineVersions from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineVersions/PipelineVersions';
import AppValues from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/AppValues';
import Webhooks from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Webhooks/Webhooks';
import DraftWrapper from '@src/containers/Orgs/Apps/containers/App/containers/ViewDraftDelta/DraftWrapper';
import CompareDeployments from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/CompareDeployments/CompareDeployments';
import DeleteEnvironment from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/DeleteEnvironment';
import Deploys from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/Deploys';
import Drafts from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Drafts/Drafts';
import EnvAutomation from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/EnvAutomation/EnvAutomation';
import EnvStatus from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/EnvStatus/EnvStatus';
import EnvValues from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/EnvValues/EnvValues';
import ViewEnvironment from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/ViewEnvironment';
import AppsList from '@src/containers/Orgs/Apps/containers/AppsList/containers/AppsList';
import EnvironmentTypes from '@src/containers/Orgs/EnvironmentTypes/EnvironmentTypes';
import ManageImages from '@src/containers/Orgs/ManageImages/ManageImages';
import OrgMemberApplicationRoles from '@src/containers/Orgs/OrgMembers/containers/OrgMemberDetails/components/OrgMemberApplicationRoles/OrgMemberApplicationRoles';
import OrgMemberEnvTypeRoles from '@src/containers/Orgs/OrgMembers/containers/OrgMemberDetails/components/OrgMemberEnvTypeRoles';
import OrgMemberRemove from '@src/containers/Orgs/OrgMembers/containers/OrgMemberDetails/components/OrgMemberRemove';
import OrgMemberDetails from '@src/containers/Orgs/OrgMembers/containers/OrgMemberDetails/OrgMemberDetails';
import OrgMembersList from '@src/containers/Orgs/OrgMembers/containers/OrgMembersList/OrgMembersList';
import Orgs from '@src/containers/Orgs/Orgs';
import OrgsRoot from '@src/containers/Orgs/OrgsRoot';
import { PipelineRunDetails } from '@src/containers/Orgs/PipelineRuns/components/PipelineRunDetails/PipelineRunDetails';
import { PipelineRunsList } from '@src/containers/Orgs/PipelineRuns/components/PipelineRunsList/PipelineRunsList';
import Registries from '@src/containers/Orgs/Registries/containers/Registries/Registries';
import ResourceDefinitionConfiguration from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionConfiguration/ResourceDefinitionConfiguration';
import ResourceDefinitionDelete from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionDelete/ResourceDefinitionDelete';
import ResourceDefinitionMatchingCriteria from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionMatchingCriteria/ResourceDefinitionMatchingCriteria/ResourceDefinitionMatchingCriteria';
import ResourceDefinitionUsage from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionUsage/ResourceDefinitionUsage';
import ResourceDefinition from '@src/containers/Orgs/Resources/components/ResourceDefinition/ResourceDefinition';
import { ResourceClasses } from '@src/containers/Orgs/Resources/containers/ResourceClasses/ResourceClasses';
import { ResourceDefinitions } from '@src/containers/Orgs/Resources/containers/ResourceDefinitions/ResourceDefinitions';
import { Resources } from '@src/containers/Orgs/Resources/Resources';
import ServiceUsers from '@src/containers/Orgs/ServiceUsers/ServiceUsers';
import ProfileSettings from '@src/containers/ProfileSettings/ProfileSettings';
import VerifyEmail from '@src/containers/VerifyEmail';
import DeploymentOrDeltaContextWrapper from '@src/context/DeploymentOrDeltaContextWrapper';
import SocketWrapper from '@src/context/SocketWrapper';
import { windowEnv } from '@src/environment';
import useUserDetailQuery from '@src/hooks/react-query/user/useUserDetailQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { useRBAC } from '@src/hooks/useRBAC';
import i18n from '@src/i18n/i18n';
import { MatchParams } from '@src/models/routing';
import RedirectRoot from '@src/RedirectRoot';
import { getSharedBreadcrumbs } from '@src/utilities/breadcrumbs-utils';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import {
  generateAppURL,
  generatePipelinesURL,
  generateResourceDefinitionUrl,
  generateResourcesUrl,
  generateSettingsURL,
} from '@src/utilities/navigation';

const RedirectToLocal = ({ url }: { url: string }) => {
  const location = useLocation();

  useEffect(() => {
    window.location.href = `${url}${location.search}`;
  }, [url, location.search]);

  return null;
};

const RedirectToSectionBasedOnRole = () => {
  const { orgRole } = useGetUserRoles();

  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return orgRole && orgRole === 'member' ? (
    <Navigate to={generateSettingsURL(orgId, 'environment-types')} />
  ) : orgRole ? (
    <Navigate to={generateSettingsURL(orgId, 'images')} />
  ) : (
    <></>
  );
};

const RedirectToOrgMemberRemove = () => {
  const { data: userDetail } = useUserDetailQuery();
  return userDetail ? <OrgMemberRemove user={userDetail} /> : null;
};

const RedirectToDraft = () => {
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;
  const canAccessDraftURL = useRBAC('accessDraftURL');
  return canAccessDraftURL ? (
    <DraftWrapper>
      <ViewDeploymentAndDeltaCommon />
    </DraftWrapper>
  ) : (
    <Navigate to={generateAppURL(orgId, appId, envId)} />
  );
};

const deploymentOrDeltaTabRoutes = (
  <>
    <Route index element={<Navigate to={'workloads'} />} />
    <Route path={'workloads'} element={<EnvironmentWorkloads />} />
    <Route path={'shared-resources'} element={<SharedResources />} />
    <Route path={'deployed-values'} element={<DeployedValues />} />
    <Route path={'graph'} element={<ResourceDependencyGraph />} />
  </>
);

const ResourcesRedirect = () => {
  // Router hooks
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  return <Navigate to={generateResourceDefinitionUrl(orgId, defId)} />;
};

const ResourcesIndex = () => {
  return <Navigate to={'definitions'} />;
};

const translations = i18n.t('NAVIGATION');

export const routes = createRoutesFromElements(
  <Route path={'/'} element={<AppRoot />}>
    <Route
      path={'devices'}
      element={
        <PrivateRoute>
          <Device />
        </PrivateRoute>
      }
      handle={{
        crumbs: () => [{ name: translations.AUTHORIZATION, label: translations.SETTINGS }],
      }}
    />
    <Route
      path={'/orgs/:orgId'}
      element={
        <PrivateRoute>
          <Orgs />
        </PrivateRoute>
      }>
      <Route index element={<OrgsRoot />} />
      <Route path={`apps`}>
        <Route index element={<AppsList />} />
        <Route
          path={':appId'}
          element={<App />}
          handle={{
            crumbs: (match, data) => getSharedBreadcrumbs(match, data),
          }}>
          <Route index element={<Navigate to={'envs'} />} />
          <Route path={'envs'} element={<Environments />} />
          <Route path={'pipelines'} element={<PipelinesList />} />
          <Route path={'values-and-secrets'} element={<AppValues />} />
          <Route path={'webhooks'} element={<Webhooks />} />
          <Route
            path={'people'}
            element={
              <div>
                <AppMembers />
              </div>
            }
          />
          <Route path={'delete'} element={<DeleteApplication />} />
        </Route>
      </Route>
      <Route path={'apps/:appId/pipelines/create'} element={<PipelineDetails isCreation />} />
      <Route
        path={'apps/:appId/pipelines/:pipelineId'}
        element={<PipelineDetails />}
        handle={{
          crumbs: (match, data) => [
            ...getSharedBreadcrumbs(match, data),
            {
              label: translations.PIPELINES,
              name: data.pipeline?.name,
              pathname: match?.pathname,
            },
          ],
        }}>
        <Route index element={<Navigate to={'runs'} />} />
        <Route path={'runs'} element={<PipelineRunsList />} />
        <Route path={'definition'} element={<PipelinesYaml />} />
        <Route path={'matching-criteria'} element={<PipelineMatchingCriteria />} />
        <Route path={'versions'} element={<PipelineVersions />} />
        <Route path={'delete'} element={<DeletePipeline />} />
      </Route>
      <Route
        path={`apps/:appId/pipelines/:pipelineId/runs/:pipelineRunId`}
        handle={{
          crumbs: (match, data) => [
            ...getSharedBreadcrumbs(match, data),
            {
              label: translations.PIPELINES,
              name: data.pipeline?.name,
              pathname: generatePipelinesURL(
                match?.params.orgId,
                match?.params.appId,
                match?.params.pipelineId
              ),
            },
            {
              label: translations.PIPELINE_RUNS,
              name: formatDate(
                data.pipelineRun?.created_at,
                DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
              ),
            },
          ],
        }}
        element={<PipelineRunDetails />}
      />
      <Route
        path={'apps/:appId/envs/:envId'}
        handle={{
          crumbs: (match, data) => getSharedBreadcrumbs(match, data),
        }}
        element={
          <SocketWrapper>
            <DeploymentOrDeltaContextWrapper>
              <ViewEnvironment />
            </DeploymentOrDeltaContextWrapper>
          </SocketWrapper>
        }>
        <Route index element={<Navigate to={'status'} />} />
        <Route path={'status'} element={<EnvStatus />} />
        <Route path={'deploys'} element={<Deploys />} />
        <Route path={'draft'} element={<Drafts />} />
        <Route path={'automation'} element={<EnvAutomation />} />
        <Route path={'values-and-secrets-overrides'} element={<EnvValues />} />
        <Route path={'delete'} element={<DeleteEnvironment />} />
      </Route>
      <Route
        path={`apps/:appId/envs/:envId/compare-deployments/:leftDeploymentId/:rightDeploymentId`}
        element={<CompareDeployments />}
        handle={{
          crumbs: (match, data) => [
            ...getSharedBreadcrumbs(match, data),
            {
              name: translations.DIFF_DEPLOYMENTS,
              pathname: generateAppURL(
                match?.params.orgId || '',
                match?.params.appId,
                match?.params.envId
              ),
            },
          ],
        }}
      />
      <Route
        path={'apps/:appId/envs/:envId/draft/:deltaId'}
        element={<RedirectToDraft />}
        handle={{
          crumbs: (match, data) => [...getSharedBreadcrumbs(match, data)],
        }}>
        {deploymentOrDeltaTabRoutes}
      </Route>
      <Route
        path={'apps/:appId/envs/:envId/deploys/:deployId'}
        handle={{
          crumbs: (match, data) => [...getSharedBreadcrumbs(match, data)],
        }}
        element={
          <SocketWrapper>
            <DeploymentOrDeltaContextWrapper>
              <ViewDeploymentAndDeltaCommon />
            </DeploymentOrDeltaContextWrapper>
          </SocketWrapper>
        }>
        {deploymentOrDeltaTabRoutes}
      </Route>
      <Route
        path={'apps/:appId/envs/:envId/draft/:deltaId/workloads/:moduleId/*'}
        handle={{
          crumbs: (match, data) => [...getSharedBreadcrumbs(match, data)],
        }}
        element={
          <DraftWrapper>
            <RenderViewWorkload />
          </DraftWrapper>
        }
      />
      <Route
        path={'apps/:appId/envs/:envId/deploys/:deployId/workloads/:moduleId/*'}
        handle={{
          crumbs: (match, data) => [...getSharedBreadcrumbs(match, data)],
        }}
        element={
          <SocketWrapper>
            <DeploymentOrDeltaContextWrapper>
              <RenderViewWorkload />
            </DeploymentOrDeltaContextWrapper>
          </SocketWrapper>
        }
      />
      <Route
        path={`resources`}
        handle={{
          crumbs: () => [
            {
              label: translations.SETTINGS,
              name: translations.RESOURCES,
            },
          ],
        }}
        element={
          <PrivateRouteForOrgRole permissionName={'viewResources'} redirectUrl={'apps'}>
            <Resources />
          </PrivateRouteForOrgRole>
        }>
        <Route index element={<ResourcesIndex />} />
        <Route path={`definitions`} element={<ResourceDefinitions />} />
        <Route path={`classes`} element={<ResourceClasses />} />
        <Route path={`:defId`} element={<ResourcesRedirect />}>
          <Route path={`usage`} element={<ResourceDefinitionUsage />} />
          <Route path={`matching-criteria`} element={<ResourceDefinitionMatchingCriteria />} />
          <Route path={'configuration'} element={<ResourceDefinitionConfiguration />} />
          <Route path={'delete'} element={<ResourceDefinitionDelete />} />
        </Route>
      </Route>
      <Route
        path={`resources/definitions/:defId`}
        element={<ResourceDefinition />}
        handle={{
          crumbs: (match) => [
            {
              label: translations.SETTINGS,
              name: translations.RESOURCES,
              pathname: generateResourcesUrl(match?.params?.orgId || ''),
            },
            {
              label: translations.DEFINITION,
              name: match?.params.defId,
            },
          ],
        }}>
        <Route path={`usage`} element={<ResourceDefinitionUsage />} />
        <Route path={`matching-criteria`} element={<ResourceDefinitionMatchingCriteria />} />
        <Route path={'configuration'} element={<ResourceDefinitionConfiguration />} />
        <Route path={'delete'} element={<ResourceDefinitionDelete />} />
      </Route>
      <Route element={<RedirectToSectionBasedOnRole />} />
      <Route
        path={`environment-types`}
        handle={{
          crumbs: () => [
            {
              label: translations.SETTINGS,
              name: translations.ENVIRONMENT_TYPES,
            },
          ],
        }}
        element={
          <PrivateRouteForOrgRole permissionName={'viewEnvironmentTypesPage'} redirectUrl={'apps'}>
            <EnvironmentTypes />
          </PrivateRouteForOrgRole>
        }
      />
      <Route
        path={`images`}
        handle={{
          crumbs: () => [
            {
              label: translations.SETTINGS,
              name: translations.CONTAINER_IMAGES,
            },
          ],
        }}
        element={
          <PrivateRouteForOrgRole permissionName={'viewImagesPage'} redirectUrl={'apps'}>
            <ManageImages />
          </PrivateRouteForOrgRole>
        }
      />
      <Route
        path={`accounts`}
        handle={{
          crumbs: () => [
            {
              label: translations.SETTINGS,
              name: translations.CLOUD_ACCOUNTS,
            },
          ],
        }}
        element={
          <PrivateRouteForOrgRole permissionName={'viewAccountsPage'} redirectUrl={'apps'}>
            <Accounts />
          </PrivateRouteForOrgRole>
        }
      />
      <Route
        path={`registries`}
        handle={{
          crumbs: () => [
            {
              label: translations.SETTINGS,
              name: translations.REGISTRIES,
            },
          ],
        }}
        element={
          <PrivateRouteForOrgRole permissionName={'viewRegistries'} redirectUrl={'images'}>
            <Registries />
          </PrivateRouteForOrgRole>
        }
      />
      <Route
        path={`service-users`}
        handle={{
          crumbs: () => [
            {
              label: translations.SETTINGS,
              name: translations.SERVICE_USERS,
            },
          ],
        }}
        element={
          <PrivateRouteForOrgRole permissionName={'viewServiceUsersPage'} redirectUrl={'settings'}>
            <ServiceUsers />
          </PrivateRouteForOrgRole>
        }
      />
      <Route
        path={`org-members`}
        handle={{
          crumbs: (match) => [
            {
              label: translations.SETTINGS,
              name: translations.ORG_MEMBERS,
              pathname: match?.pathname,
            },
          ],
        }}
        element={
          <PrivateRouteForOrgRole permissionName={'viewOrgMembersPage'} redirectUrl={'apps'}>
            <Outlet />
          </PrivateRouteForOrgRole>
        }>
        <Route index element={<OrgMembersList />} />
        <Route
          path={':userId'}
          element={<OrgMemberDetails />}
          handle={{
            crumbs: (match, data) => [
              {
                label: translations.USER,
                name: data.userDetail?.name,
              },
            ],
          }}>
          <Route path={'applications'} element={<OrgMemberApplicationRoles />} />
          <Route path={'environment-types'} element={<OrgMemberEnvTypeRoles />} />
          <Route path={'remove'} element={<RedirectToOrgMemberRemove />} />
        </Route>
      </Route>
      <Route path={`*/*`} element={<RedirectToSectionBasedOnRole />} />
      <Route path={`app-not-found`} element={<ErrorPage title={'App not found'} />} />
      <Route path={'*'} element={<ErrorPage />} />
    </Route>
    <Route
      path={'/profile-settings'}
      element={
        <PrivateRoute>
          <ProfileSettings />
        </PrivateRoute>
      }
      handle={{ crumbs: () => [{ name: translations.ACCOUNT }] }}
    />
    <Route path={'/verify-email/:confirmationEmailHash'} element={<VerifyEmail />} />
    <Route path={'/'} element={<RedirectRoot />} />
    <Route path={'/form-playground'} element={<FormPlayground />} />
    <Route path={'/workload-profile-playground'} element={<WorkloadProfilePlayground />} />
    <Route path={'*'} element={<RedirectRoot />} />
    <Route path={'auth'} element={<Auth />}>
      <Route
        path={`signup`}
        element={
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        }
      />
      <Route
        path={`registermvp`}
        element={
          <PublicRoute>
            <SignUp disableFreeTrial />
          </PublicRoute>
        }
      />
      <Route
        path={`login`}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path={`sso-login`}
        element={
          <PublicRoute>
            <SSOLogin />
          </PublicRoute>
        }
      />
      <Route
        path={`github-login`}
        element={
          <PublicRoute>
            <GithubLogin />
          </PublicRoute>
        }
      />
      <Route
        path={`github-login-invite`}
        element={
          <PublicRoute>
            <GithubLoginInvite />
          </PublicRoute>
        }
      />
      <Route
        path={`github-register`}
        element={
          <PublicRoute>
            <GithubRegister />
          </PublicRoute>
        }
      />
      <Route
        path={`github-register-mvp`}
        element={
          <PublicRoute>
            <GithubRegisterMVP />
          </PublicRoute>
        }
      />
      <Route
        path={`github-register-invite`}
        element={
          <PublicRoute>
            <GithubRegisterInvite />
          </PublicRoute>
        }
      />
      <Route path={`accept-invite`} element={<AcceptInvite />} />
      <Route
        path={`account-details`}
        element={
          <PrivateRoute>
            <AccountDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={`create-organization`}
        element={
          <PrivateRoute>
            <CreateOrganization />
          </PrivateRoute>
        }
      />
      {/* To test locally */}
      {windowEnv.BASE_URL === 'https://dev-api.humanitec.io' && (
        <>
          <Route
            path={`github-register-local`}
            element={<RedirectToLocal url={`http://localhost:4200/auth/github-register`} />}
          />
          <Route
            path={`github-register-local-mvp`}
            element={<RedirectToLocal url={`http://localhost:4200/auth/github-register-mvp`} />}
          />
          <Route
            path={`github-register-invite-local`}
            element={<RedirectToLocal url={`http://localhost:4200/auth/github-register-invite`} />}
          />
          <Route
            path={`github-login-local`}
            element={<RedirectToLocal url={`http://localhost:4200/auth/github-login`} />}
          />
          <Route
            path={`github-login-invite-local`}
            element={<RedirectToLocal url={`http://localhost:4200/auth/github-login-invite`} />}
          />
        </>
      )}
    </Route>
  </Route>
);
