import { rem } from 'polished';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { useWalhallContext } from '@src/context/walhallContext';
import { units } from '@src/styles/variables';
import { darkPalette } from '@src/theme/color-palette';

import EmptyStateCard from '../../molecules/EmptyStateCard/EmptyStateCard';
import { WalLabel } from '../Label';

const DiffWrapper = styled.div`
  margin-bottom: ${units.margin.sm};
  font-size: ${units.fontSize.sm};
  ${({ $rounded }: { $rounded: boolean }) =>
    $rounded &&
    css`
      border-radius: ${rem(4)};
      padding: ${units.padding.xs} ${units.padding.xxs} ${units.padding.sm} ${units.padding.xxs};
      background: ${({ theme }) => theme.color.baseDarker};
    `}
`;

interface DiffViewerProps {
  newValue?: string;
  oldValue?: string;
  label?: string;
  showNoChangesText?: boolean;
  showDiffOnly?: boolean;
  className?: string;
  rounded?: boolean;
}

const DiffViewer = ({
  newValue,
  oldValue,
  label,
  showNoChangesText = true,
  showDiffOnly = true,
  className,
  rounded = false,
  ...props
}: DiffViewerProps) => {
  // Context
  const { theme } = useWalhallContext();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const reactDiffViewerStyles = {
    variables: {
      dark: {
        diffViewerBackground: darkPalette.baseDarker,
        gutterColor: darkPalette.text,
        emptyLineBackground: darkPalette.baseDarker,
      },
    },
    diffContainer: `
    word-break: break-all; 
    pre { 
      line-height: 15px !important; 
    } 
    a {
      text-decoration:none !important
    } 
    tr td:last-child pre {
      margin-right:${units.padding.xxl};
    };`,
  };

  return oldValue === newValue && showNoChangesText ? (
    <EmptyStateCard>{uiTranslations.NO_CHANGES}</EmptyStateCard>
  ) : (
    <DiffWrapper className={className} $rounded={rounded}>
      {label && <WalLabel>{label}</WalLabel>}
      <ReactDiffViewer
        data-testid={'diff-viewer'}
        splitView={false}
        newValue={newValue}
        oldValue={oldValue}
        compareMethod={DiffMethod.JSON}
        hideLineNumbers
        useDarkTheme={theme === 'dark'}
        styles={reactDiffViewerStyles}
        showDiffOnly={showDiffOnly}
        {...props}
      />
    </DiffWrapper>
  );
};

export default DiffViewer;
