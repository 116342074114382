import { ErrorWarning, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DeploymentOverviewModalContent } from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModalContent/DeploymentOverviewModalContent';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import usePipelineDeployMutation from '@src/hooks/react-query/pipeline-runs/mutation/usePipelineDeployMutation';
import usePipelineCriteriaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineCriteriaQuery';
import useSharedAppValuesHistoryQuery from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { PipelineCriteria, PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import { diffDeploymentSets } from '@src/utilities/deployment-delta-utils';
import { useWalhallForm } from '@src/utilities/form';
import makeRequest from '@src/utilities/make-request';

interface DeploymentOverviewModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  deploymentType:
    | { type: 'deploy'; data: DeploymentDelta }
    | { type: 'redeploy'; data: DeploymentObject; deployLatestValueSet?: boolean };
  environment: Environment;
  title?: string;
  customOnDeploymentSuccess?: (pipelineRun: PipelineRun) => void;
  deployLatestValueSetMode?: boolean;
}

const DeploymentOverviewModal = ({
  openState,
  deploymentType,
  environment,
  title,
  customOnDeploymentSuccess,
}: DeploymentOverviewModalProps) => {
  // i18n
  const { t } = useTranslation();
  const deploymentModalTranslations = t('DEPLOYMENT_MODAL');

  // Form
  const methods = useWalhallForm<{ comment: string }>();
  const { setValue, handleSubmit } = methods;

  // Component state
  const [modalOpen, setModalOpen] = openState;
  const [deltaCreationError, setDeltaCreationError] = useState<boolean>(false);
  const [generateDelta, setGenerateDelta] = useState(false);

  const [displayedDelta, setDisplayedDelta] = useState<DeploymentDelta>();
  const [deployLatestValueSet, setDeployLatestValueSet] = useState<boolean>();

  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: activeDelta } = useDeploymentDeltaQuery();
  const { responseData: envValueSets = [] } = useSharedAppValuesHistoryQuery(true, {
    envId: environment.id,
  });

  const {
    mutate: deployThroughMatchedPipeline,
    error: deploymentAPIError,
    isPending: isDeploying,
  } = usePipelineDeployMutation({
    envId: environment.id,
    onSuccess: customOnDeploymentSuccess ? customOnDeploymentSuccess : undefined,
  });
  const { data: pipelineCriteria } = usePipelineCriteriaQuery({
    env_id: environment?.id,
    env_type: environment?.type,
    deployment_type: deploymentType.type,
  });

  const matchedPipeline: PipelineCriteria | undefined = pipelineCriteria?.[0];

  useEffect(() => {
    if (deploymentType.type === 'redeploy' && deploymentType.deployLatestValueSet) {
      setDeployLatestValueSet(true);
    }
  }, [deploymentType]);

  // set the delta to display the diff from
  useEffect(() => {
    if (!modalOpen) return;
    if (deploymentType.type === 'deploy') {
      // if there is an active delta(draft) display it
      setDisplayedDelta(deploymentType.data);
    } else if (activeDelta) {
      setDisplayedDelta(activeDelta);
    } else if (modalOpen) {
      // get the diff between the displayed set and the last deployed set
      if (
        !generateDelta &&
        deploymentType.type === 'redeploy' &&
        deploymentType.data?.set_id &&
        environment?.last_deploy
      ) {
        setGenerateDelta(true);
        diffDeploymentSets(
          deploymentType.data?.set_id,
          environment?.last_deploy?.set_id,
          appId,
          orgId
        ).then((diffBaseDelta) => {
          makeRequest<string>(
            // make request is used here because the delta is temporary and should not be added to the store
            'post',
            `/orgs/${orgId}/apps/${appId}/deltas`,
            diffBaseDelta
          )
            .then((createdDeltaRes) => {
              if (createdDeltaRes) {
                const delta = {
                  ...diffBaseDelta,
                  id: createdDeltaRes.data,
                };
                setDisplayedDelta(delta);
              }
            })
            .catch(() => {
              setDeltaCreationError(true);
            });
        });
      }
    }
  }, [
    activeDelta,
    environment,
    appId,
    orgId,
    generateDelta,
    setGenerateDelta,
    deploymentType.type,
    deploymentType.data,
    modalOpen,
  ]);

  const deploy = (formState: { comment: string }) => {
    if (displayedDelta && environment) {
      setValue('comment', '');
      if (deploymentType.type === 'deploy') {
        deployThroughMatchedPipeline({
          inputs: {
            delta_id: displayedDelta.id,
            comment: formState.comment,
          },
        });
      } else if (deploymentType.type === 'redeploy') {
        deployThroughMatchedPipeline({
          inputs: {
            set_id: deploymentType.data.set_id,
            value_set_version_id: deployLatestValueSet
              ? envValueSets[0]?.id
              : deploymentType.data.value_set_version_id,
            comment: formState.comment,
          },
        });
      }
    }
    setModalOpen(false);
  };

  const isRedeployingLatestValuesAndSecrets =
    deploymentType.type === 'redeploy' && deploymentType.deployLatestValueSet;

  return (
    <FormProvider {...methods}>
      <WalModal
        title={
          isRedeployingLatestValuesAndSecrets
            ? `${deploymentModalTranslations.REDEPLOY_LATEST_VALUES_TO} ${environment?.id}`
            : title ||
              `${
                deploymentType.type === 'deploy'
                  ? deploymentModalTranslations.DEPLOY_CHANGES_TO
                  : deploymentModalTranslations.REDEPLOY_CHANGES_TO
              } ${environment?.id}`
        }
        openState={[modalOpen, setModalOpen]}
        handleFormSubmit={handleSubmit(deploy)}
        content={
          <>
            <DeploymentOverviewModalContent
              displayedDelta={displayedDelta}
              deltaCreationError={deltaCreationError}
              deploymentType={deploymentType}
              onChangeValueSet={(value) => setDeployLatestValueSet(value === 'latest')}
              isRedeployingLatestValuesAndSecrets={isRedeployingLatestValuesAndSecrets}
              matchedPipeline={matchedPipeline}
            />
            {deploymentAPIError && (
              <ErrorWarning
                className={'my-md'}
                code={deploymentAPIError?.response?.data?.error}
                message={deploymentAPIError?.response?.data?.message}
                mode={'alert'}
                disableDefaultMargin
              />
            )}
          </>
        }
        actions={{
          main: {
            text:
              environment.last_deploy?.status === 'in progress'
                ? deploymentModalTranslations.CURRENTLY_DEPLOYING
                : deploymentType.type === 'deploy'
                  ? deploymentModalTranslations.DEPLOY
                  : deploymentModalTranslations.REDEPLOY,
            props: {
              disabled:
                deltaCreationError ||
                environment.last_deploy?.status === 'in progress' ||
                !displayedDelta ||
                !matchedPipeline,
              loading: isDeploying || environment.last_deploy?.status === 'in progress',
              type: 'submit',
            },
          },
          cancel: {},
        }}
        size={'large'}
      />
    </FormProvider>
  );
};

export default DeploymentOverviewModal;
