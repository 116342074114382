import { CloseIcon, EmptyStateCard, FlowDiagram, Spinner, WalCard } from '@humanitec/ui-components';
import { Edge, MarkerType, Node } from '@xyflow/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import ResourceCardDetails from '@src/components/shared/ResourceDependencyList/components/ResourceCardDetails/ResourceCardDetails';
import ResourceNode from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/ResourceDependencyGraph/components/ResourceNode';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentQuery from '@src/hooks/react-query/environments/queries/useDeploymentQuery';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import useResourceDependencyGraphQuery from '@src/hooks/react-query/resources/queries/useResourceDependencyGraphQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { useResourceDeploymentTimes } from '@src/hooks/useResourceDeploymentTimes';
import { DeploymentObject } from '@src/models/deployment-object';
import {
  ActiveResource,
  ResourceCategory,
  ResourceDependencyGraph as ResourceDependencyGraphModel,
  ResourceDependencyGraphNode,
  ResourceTypes,
} from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateDraftURL } from '@src/utilities/navigation';

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  row-gap: ${units.margin.md};
  column-gap: ${units.margin.md};
  margin-bottom: ${units.margin.md};
  overflow: auto;
`;

const SideBar = styled(WalCard)`
  flex: 1 1 180px;
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  border-radius: 4px;
`;

const ReactFlowContainer = styled.div<{ hideContainerBorder?: boolean }>`
  flex-grow: 1;
  min-width: 80%;
  min-height: 70%;
  ${({ hideContainerBorder, theme }) =>
    !hideContainerBorder &&
    css`
      border: 1px solid ${theme.color.baseOutline};
    `}
  border-radius: 4px;
`;

const FullWidthEmptyCard = styled(EmptyStateCard)`
  width: 100%;
`;
const nodeTypes = {
  resourceNode: ResourceNode,
};

export interface ResourceNodeData extends Record<string, unknown> {
  res_id?: string;
  guresid: string;
  class: string;
  def_id: string;
  type: ResourceTypes;
  provisionTime?: string;
  category?: ResourceCategory;
}

interface ResourceDependencyGraphProps {
  customDeployment?: DeploymentObject;
  customResourceDependencyGraph?: Partial<ResourceDependencyGraphModel>;
  hideContainerBorder?: boolean;
}

const ResourceDependencyGraph = ({
  customDeployment,
  customResourceDependencyGraph,
  hideContainerBorder,
}: ResourceDependencyGraphProps) => {
  // Router hooks
  const { orgId, appId, envId, deltaId } = useParams<keyof MatchParams>() as MatchParams;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // i18n
  const { t } = useTranslation();
  const resourceDependencyGraphTranslations = t('RESOURCE_DEPENDENCY_GRAPH');

  // state
  const [selectedResource, setSelectedResource] = useState<ActiveResource>();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

  // react query
  const { data: deployment } = useDeploymentQuery();
  const { data: activeResources } = useEnvironmentResourcesQuery();
  const {
    data: currentDeploymentResourceDependencyGraph,
    isFetching: resourceDependencyGraphFetching,
  } = useResourceDependencyGraphQuery(
    deployment?.dependency_graph_id,
    !customResourceDependencyGraph
  );
  const resourceDependencyGraph =
    customResourceDependencyGraph || currentDeploymentResourceDependencyGraph;

  const { data: resourceTypes } = useResourceTypesQuery();

  const provisionTimes = useResourceDeploymentTimes(
    customDeployment || deployment,
    activeResources,
    resourceDependencyGraph?.nodes
  );

  const nodes: Node<ResourceNodeData>[] = useMemo(
    () =>
      resourceDependencyGraph?.nodes?.map((node) => ({
        id: node.guresid,
        position: { x: 0, y: 0 },
        type: 'resourceNode',
        data: {
          res_id: node.id,
          guresid: node.guresid,
          class: node.class,
          def_id: node.def_id,
          type: node.type,
          provisionTime: provisionTimes[node.guresid]?.provision_time,
          category: resourceTypes?.find((rt) => rt.type === node.type)?.category,
        },
      })) || [],
    [resourceDependencyGraph, provisionTimes, resourceTypes]
  );

  const edges = useMemo(
    () =>
      resourceDependencyGraph?.nodes?.reduce((acc: Edge[], node: ResourceDependencyGraphNode) => {
        return node.depends_on.length > 0
          ? [
              ...acc,
              ...node.depends_on.map((dependencyGuresId) => ({
                id: `${node.guresid}-${dependencyGuresId}`,
                source: node.guresid,
                target: dependencyGuresId,
                markerEnd: { type: MarkerType.ArrowClosed, strokeWidth: 5 },
              })),
            ]
          : acc;
      }, []) || [],
    [resourceDependencyGraph]
  );

  const handleNodeClick = (node: Node<ResourceNodeData>) => {
    setSelectedResource(
      activeResources?.find((activeResource) => activeResource.gu_res_id === node.data.guresid)
    );
    setSidebarOpen(true);
  };

  const handlePaneClick = () => {
    if (selectedResource) {
      setSelectedResource(undefined);
    }
  };

  if (draftModeActive) {
    return <Navigate to={generateDraftURL(orgId, appId, envId, deltaId, 'workloads')} />;
  }
  return (
    <Container>
      <Wrapper className={'flex-column'}>
        {resourceDependencyGraph ? (
          <>
            <ReactFlowContainer
              data-testid={'react-flow-wrapper'}
              hideContainerBorder={hideContainerBorder}>
              <FlowDiagram
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                showControls
                onNodeClick={handleNodeClick}
                onPaneClick={handlePaneClick}
              />
            </ReactFlowContainer>
            {sidebarOpen && selectedResource && (
              <SideBar>
                <CloseIcon onClick={() => setSidebarOpen(false)} />
                <div className={'flex-row flex-centered mb-lg'}>
                  <ResourceIcon type={selectedResource.type} hasMargin />
                  <span className={'mr-md'}>{selectedResource.res_id}</span>
                </div>
                <ResourceCardDetails
                  activeResource={selectedResource}
                  provisionTime={provisionTimes[selectedResource.gu_res_id]?.provision_time}
                  hideResourceId
                  showDriverType
                  showDriverAccount
                />
              </SideBar>
            )}
          </>
        ) : resourceDependencyGraphFetching ? (
          <Spinner />
        ) : (
          <FullWidthEmptyCard>
            {resourceDependencyGraphTranslations.NO_GRAPH_AVAILABLE}
          </FullWidthEmptyCard>
        )}
      </Wrapper>
    </Container>
  );
};

export default ResourceDependencyGraph;
