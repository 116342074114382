import { ExpandableCard } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ResourceCardDetails from '@src/components/shared/ResourceDependencyList/components/ResourceCardDetails/ResourceCardDetails';
import ResourceCardHeaderContent from '@src/components/shared/ResourceDependencyList/components/ResourceCardHeaderContent/ResourceCardHeaderContent';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import { MatchParams } from '@src/models/routing';

const WorkloadResource = () => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  // React Query
  const { data: environmentResources = [] } = useEnvironmentResourcesQuery();

  // Router
  const { deployId, moduleId } = useParams<keyof MatchParams>() as MatchParams;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const currentWorkloadResource = environmentResources.find(
    (resource) =>
      resource.type === 'workload' &&
      resource.deploy_id === deployId &&
      resource.res_id === `modules.${moduleId}`
  );

  return (
    <div>
      {currentWorkloadResource ? (
        <ExpandableCard
          id={currentWorkloadResource?.def_id}
          headerContent={
            <ResourceCardHeaderContent
              scope={'private'}
              resourceId={currentWorkloadResource.def_id}
              resourceType={currentWorkloadResource.type}
              resourceClass={currentWorkloadResource.class}
              draftModeActive={draftModeActive}
              iconSize={30}
            />
          }
          content={<ResourceCardDetails activeResource={currentWorkloadResource} />}
          cardStyle={'base'}
          dataTestId={'workload-dependency-card'}
        />
      ) : (
        <span>{sectionsTranslations.NO_WORKLOAD_RESOURCE_DEFINED}</span>
      )}
    </div>
  );
};

export default WorkloadResource;
