import { EditorProps } from '@monaco-editor/react';
import { JSONSchema7 } from 'json-schema';
import { get, has } from 'lodash';
import { editor } from 'monaco-editor';
import { rem } from 'polished';
import React, {
  ClipboardEvent,
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ErrorOption, useFormContext, Validate, ValidationValueMessage } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { ErrorMoreInformationLink, InputExtraError } from '@src/components/shared-types/input';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import {
  capitalize,
  GranularRegexMatch,
  ID_START_WITH_APLHABETIC_VALIDATIONS,
  ID_VALIDATIONS,
  isNumeric,
  KEY_REGEXP,
  lowercaseFirstLetter,
  NAME_VALIDATIONS,
  PATH_VALIDATIONS,
  RESOURCE_ID_REGEXP,
  SUB_PATH_VALIDATIONS,
  URL_PATH_REGEXP,
  VOLUME_PATH_REGEXP,
} from '@src/utilities/string-utility';

import { AutoCompleteOptions } from '../../molecules/AutoCompleteInput/AutoCompleteInput';
import { CopyText } from '../../molecules/CopyText/CopyText';
import { FormFieldGeneratorDefinition } from '../../molecules/FormGenerator/FormGenerator';
import Tooltip from '..//Tooltip/Tooltip';
import BasicEditor from '../Editor/BasicEditor';
import Icon from '../Icon/Icon';
import { inputCss } from './styles/mixins';

const InputIcon = styled.span`
  position: absolute;
  top: 1.75rem;
  right: 0.5rem;
  display: inline-flex;
  z-index: 10;
`;
export const inputHeight = {
  large: rem(40),
  medium: rem(32),
  small: rem(24),
};

export const INPUT_LABEL_MARGIN = units.margin.lg;

export type InputSize = 'small' | 'medium' | 'large';

type ValidationNames =
  | 'id'
  | 'key'
  | 'existingId'
  | 'path'
  | 'volume-path'
  | 'sub-path'
  | 'name'
  | 'urlPath'
  | 'idStartWithAlphabetic';

interface StandardValidations {
  type: 'key' | 'path' | 'volume-path' | 'sub-path' | 'name' | 'idStartWithAlphabetic';
}

interface IDValidation {
  type: 'id';
  /** Exceptions to the ID rule */
  exceptions?: string[];
}

interface ResourceIDValidation {
  type: 'resourceId';
  /** Exceptions to the ID rule */
  exceptions?: string[];
}

interface ExistingIDValidation {
  type: 'existingId';
  /** The ids which already exist */
  ids: string[];
  customMessage?: string;
}

interface SingleLineValidations {
  type: 'urlPath';
}

export type ValidationTypes =
  | StandardValidations
  | ExistingIDValidation
  | IDValidation
  | ResourceIDValidation
  | SingleLineValidations;

/**
 * Interface for validators
 */
export interface InputValidatorProps<T> {
  /** The value of the validator */
  value?: T;
  /** The validation message */
  message?: string;
}

interface InputFieldProps {
  noLabelOverflow: boolean;
  noMargin: boolean;
  hideLabel: boolean;
}

interface InputLabelProps {
  size: InputSize;
  readOnly?: boolean;
  labelExtensionSet?: boolean;
  hideLabel?: boolean;
  $error?: boolean;
  valueSet?: boolean;
}

/**
 * Interface for the HTML Input Element.
 */
export interface InputElementProps {
  /** If the field is invalid */
  invalid?: boolean;
  /** The size of the element */
  inputSize?: InputSize;
  /** readonly state */
  readOnly?: boolean;
  allowArrowScrolling?: boolean;
  fakeInput?: boolean;
  noHover?: boolean;
  noBorderRadius?: 'all' | 'right' | 'left';
  /** Makes the input transparent */
  transparent?: boolean;
  rows?: number;
  fontFamily?: 'default' | 'source-code';
  /** Add padding at the right end to give space for an icon*/
  iconRight?: boolean;
  /** Should only be used when having an input inside an input e.g. ComboSelect */
  hideOutline?: boolean;
  /** Minimum height of the fake input*/
  fakeInputMinHeight?: number;
  placeholder?: string;
}

/**
 * Props for the input component
 */
export interface WalInputProps {
  /** The name of the input */
  name: string;
  /** The input type */
  type?: 'text' | 'number' | 'password';
  /** The label attached to the input */
  label?: string;
  /** The label extension text */
  labelExtensionText?: string;
  /** The placeholder text */
  placeholder?: string;
  /** The size of the input */
  size?: InputSize;
  /** The id of the input */
  id?: string;
  /** If the input is readonly */
  readonly?: boolean;
  /** if the input should be scrollable when it is readonly */
  allowArrowScrolling?: boolean;
  /** whether the input content should be displayed as text instead on input */
  viewMode?: boolean;
  /** If the input is required */
  required?: boolean;
  /** Max length of input */
  maxLength?: number;
  /** Min length of input */
  minLength?: number;
  /** Maximum number */
  max?: number;
  /** Minimum number */
  min?: number;
  /** Pattern to match */
  pattern?: ValidationValueMessage<RegExp>;
  /** Number of default rows */
  rows?: number;
  /** Default input value */
  defaultValue?: string;
  /** Custom class */
  className?: string;
  /** on validate callback for the monaco editor */
  monacoEditorOnValidate?: (errs: editor.IMarker[]) => void;
  /** onChange html event */
  onChange?: (value: string) => void;
  /** onInput html event */
  onInput?: (element: Element) => void;
  /** onBlur html event */
  onBlur?: (value: string) => void;
  /** onBlur html event */
  onFocus?: (value: string | null) => void;
  /** onClick html event */
  onClick?: (event: MouseEvent) => void;
  /** onMouseDown html event */
  onMouseDown?: (event: MouseEvent) => void;
  /** onKeyDown html event */
  onKeyDown?: (event: KeyboardEvent) => void;
  /** onKeyUp html event */
  onKeyUp?: (event: KeyboardEvent) => void;
  /** onPaste html event for content in the fake input div */
  onPaste?: (event: ClipboardEvent) => void;
  /** Optional ref. e.g. for focusing on input from parent component */
  inputRef?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement | null>;
  /** Custom error validation */
  validate?: Record<string, Validate<string, any>>;
  /** A set of standard validation for inputs */
  standardValidation?: ValidationTypes[];
  /** if the input has a top margin */
  noMargin?: boolean;
  /** Hide the label field */
  hideLabel?: boolean;
  /** If you want a div that looks like an input */
  fakeInput?: boolean;
  /** Minimum height of the fake input*/
  fakeInputMinHeight?: number;
  /** Only for use with 'fakeInput' */
  children?: any;
  /** force the input to propagate click event to children */
  propagateClickEvent?: boolean;
  noHover?: boolean;
  /** no border radius */
  noBorderRadius?: 'all' | 'right' | 'left';
  noLabelOverflow?: boolean;
  /** Makes the input transparent */
  transparent?: boolean;
  /** Set to true if the label should always be above of the input, and not act as placeholder */
  labelAbove?: boolean;
  description?: string;
  dataTestId?: string;
  ariaControls?: string;
  fontFamily?: 'default' | 'source-code';
  valueAsNumber?: boolean;
  /** https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
  autoComplete?: string;
  tabIndex?: number;
  /** If using formgenerator */
  hideError?: boolean;
  /**
   * More information link for error.
   * To display error for the whole input, pass a string.
   * To display errors for individual error types, use object. The key is the error type e.g. 'required', the value is the link.
   */
  errorMoreInformationLink?: ErrorMoreInformationLink;
  /**
   * Set true if the input is a secret. In that case:
   * * `type` is changed to `password` to mask input text
   * * A padlock icon is displayed to the right end of input
   */
  secret?: boolean;
  /** Should only be used when having an input inside an input e.g. ComboSelect */
  hideOutline?: boolean;
  /** pass this if we need to show placeholders autocomplete. */
  autoCompleteOptions?: AutoCompleteOptions;
  /** text that appears as input placeholder */
  placeholderText?: string;
  /** This is used to inform input to get focussed. Its used mainly in KeyValueEdit component*/
  focusOnInputState?: [boolean, (setFocus: boolean) => void];
  /** whether the input should use Monaco editor */
  isMonacoEditor?: boolean;
  /** extenstion for monaco editor that will be used for syntax highlighting */
  monacoEditorFileExtension?: string;
  /** optional schema for yaml validation in monaco editor */
  yamlSchema?: { uri: string; schema: JSONSchema7 };
  /** optional props to display the monaco editor specific context menu */
  monacoOptions?: EditorProps['options'];
  deleteAction?: () => void;
}

export const InputField = styled.div<InputFieldProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ noLabelOverflow }) =>
    noLabelOverflow &&
    css`
      overflow: unset;
    `}
  ${({ noMargin, hideLabel }) => {
    return noMargin || hideLabel
      ? css`
          padding-top: 0;
        `
      : css`
          padding-top: ${INPUT_LABEL_MARGIN};
        `;
  }}
`;

export const InputLabel = styled.label<InputLabelProps>`
  width: calc(100% - 10px);
  ${ellipsisStyle()}
  position: absolute;
  color: ${({ theme }) => theme.color.textTranslucent};
  top: ${({ size }) => (size === 'large' ? rem(30) : size === 'medium' ? rem(28) : rem(25))};
  left: ${rem(8)};
  font-size: ${({ size }) =>
    size === 'large'
      ? units.fontSize.base
      : size === 'medium'
        ? units.fontSize.sm
        : units.fontSize.sm};
  margin: 0px;
  display: block;
  white-space: nowrap;
  transition: all linear 0.2s;
  pointer-events: none;
  z-index: 2;
  &:hover {
    cursor: text;
  }
  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
    `}
  ${({ labelExtensionSet, $error, hideLabel, valueSet }) => {
    if ((labelExtensionSet && hideLabel) || (hideLabel && valueSet)) {
      return css`
        display: none !important;
      `;
    }

    if ($error) {
      return css`
        top: ${rem(3)};
        left: ${rem(0)};
        color: ${({ theme }) => theme.color.alertBrighter};
        font-size: ${units.fontSize.sm};
      `;
    } else if (labelExtensionSet) {
      return css`
        top: ${rem(3)};
        left: ${rem(0)};
        color: ${({ theme }) => theme.color.textTranslucent};
        font-size: ${units.fontSize.sm};
      `;
    }
  }}

  ${({ hideLabel, size }) =>
    hideLabel &&
    css`
      top: ${rem(size === 'small' ? 5 : 8)};
    `}

    ${({ labelExtensionSet }) =>
    labelExtensionSet &&
    css`
      top: 0px;
    `}
`;

const Input = styled.input<InputElementProps>`
  ${(props) => inputCss(props)}
`;

type TextareaProps = InputElementProps & {
  isExpandableTextareasEnabled: boolean;
  minHeight: number;
};
const Textarea = styled.textarea<TextareaProps>`
  ${(props) => inputCss(props)}
  /** Prevent user from resizing horizontally since padlock icon cannot move with textarea when it is a secret field*/
  min-width: 100%;
  ${({ isExpandableTextareasEnabled, minHeight }) =>
    isExpandableTextareasEnabled
      ? css`
          resize: vertical;
          min-height: ${rem(minHeight)};
        `
      : css`
          resize: none;
        `};
`;

/**
 * Wrapper around monaco editor.
 * TODO: Change this to enable auto increase in height (handled in different task)
 */
const MonacoEditorWrapper = styled.div<{
  size: InputSize;
  minHeight?: number;
  resizeable: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.baseLayer};
  border: ${rem(1)} solid ${({ theme }) => theme.color.baseOutline};
  border-radius: ${rem(4)};
  transition: border linear 0.2s;
  &:hover,
  &:focus {
    ${() => css`
      border-color: ${({ theme }) => theme.color.mainDarker};
    `}
  }

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${rem(minHeight)};
    `}

  ${({ resizeable }) =>
    resizeable &&
    css`
      resize: vertical;
      overflow: auto;
    `}
`;
const FakeInput = styled.span<InputElementProps>`
  ${(props) => inputCss(props)}
  display: inline;
  line-height: ${({ inputSize }) => (inputSize === 'large' ? 2.1 : 1.8)};
  height: auto;
  overflow: hidden;
  outline-offset: ${rem(-2)};
  z-index: 1;
  cursor: text;
  white-space: pre-wrap;
  &:empty:before {
    content: '${({ placeholder }) => placeholder}';
    color: ${({ theme }) => theme.color.textTranslucent};
  }
  ${({ fakeInputMinHeight }) =>
    fakeInputMinHeight &&
    css`
      min-height: ${rem(fakeInputMinHeight)};
    `}

  ${({ readOnly, theme, placeholder }) =>
    readOnly &&
    css`
      &:empty:before {
        content: '${placeholder}';
        color: ${theme.color.textTranslucent};
        opacity: 0;
      }
    `}

  ${({ fakeInput }) =>
    fakeInput &&
    css`
      display: flex;
      position: relative;
      overflow: auto;
      cursor: text;
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
    `}
`;

const HiddenInput = styled.input`
  display: none;
`;

export const InputDescription = styled.div`
  width: 100%;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-top: ${units.margin.xs};
  margin-bottom: ${rem(3)};
  grid-column: 1/-1;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const ViewModeText = styled.span`
  color: ${({ theme }) => theme.color.text};
  font-size: ${units.fontSize.lg};
  margin-bottom: ${units.margin.md};
  white-space: pre-wrap;
  line-break: anywhere;
`;

const validationMessages = (
  label?: string
): { type: ValidationNames; message: Validate<string, string> }[] => [
  {
    type: 'key',
    message: (value: string) =>
      KEY_REGEXP.test(value) ||
      `${label ? `${label} can` : 'Can'} only contain letters, numbers, '-', '_' or '.'`,
  },
  {
    type: 'volume-path',
    message: (value: string) =>
      value
        ? VOLUME_PATH_REGEXP.test(value) ||
          `${label ? `${label} is an invalid path` : 'Invalid path'}`
        : true,
  },
  {
    type: 'urlPath',
    message: (value: string) =>
      value
        ? URL_PATH_REGEXP.test(value) ||
          (label ? `${label} should start with /` : 'Path should start with / /d')
        : true,
  },
];

const NewErrorWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.alertInput};
  border-radius: ${rem(4)};
  padding: ${rem(8)};
  margin-top: ${rem(4)};
  font-size: ${rem(11)};
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
`;

const ListUL = styled.ul<{ oneField?: boolean }>`
  margin: 0;
  ${({ oneField }) =>
    oneField
      ? css`
          list-style: none;
          padding-left: 0;
        `
      : css`
          padding-left: ${units.padding.xl};
        `}
`;

interface DisplayFieldErrorProps {
  fieldError: ErrorOption;
  oneField: boolean;
  label: string;
  errorMoreInformationLink?: ErrorMoreInformationLink;
}

const ErrorLink = ({ href }: { href: string }) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  return (
    <a href={href} target={'_blank'} rel={'noopener noreferrer'}>
      {uiTranslations.MORE_INFORMATION}
    </a>
  );
};

const replaceLabelAndCapitalize = (type?: string, message?: string, label?: string) =>
  capitalize(
    (label ? message?.replace(label, type === 'required' ? 'This field' : '') : message)?.trim() ||
      ''
  );

const DisplayFieldError = ({
  fieldError,
  oneField,
  label,
  errorMoreInformationLink,
}: DisplayFieldErrorProps) => {
  const href =
    typeof errorMoreInformationLink !== 'string' &&
    fieldError.type &&
    errorMoreInformationLink?.[fieldError.type];

  return Object.keys(fieldError.types || {}).length > 1 ? (
    <>
      {!oneField && <b>{label}</b>}
      <ListUL oneField={oneField}>
        {Object.entries(fieldError.types || {}).map(([type, message]) => (
          <li key={type}>
            {typeof message === 'string'
              ? replaceLabelAndCapitalize(type, message, label)
              : message}{' '}
            {typeof errorMoreInformationLink !== 'string' && errorMoreInformationLink?.[type] && (
              <ErrorLink href={errorMoreInformationLink?.[type]} />
            )}
          </li>
        ))}
      </ListUL>
    </>
  ) : (
    <>
      <span>
        {!oneField && <b>{label}</b>}{' '}
        {oneField
          ? replaceLabelAndCapitalize(fieldError.type, fieldError.message, label)
          : lowercaseFirstLetter(fieldError.message?.replace(label, '') || '')}{' '}
        {href && <ErrorLink href={href} />}
      </span>
    </>
  );
};

interface DisplayFieldErrorsProps {
  fieldErrors: ErrorOption | Record<string, ErrorOption>;
  /** Specifies if it's a single field & will result in no nesting of errors (list items). Only specify this in the individual form components. It is automatically set to true for FormGenerator when the number of fields is 1  */
  oneField: { label?: string } | boolean;
  // Only for FormGenerator
  fields?: FormFieldGeneratorDefinition[];
  testId?: string;
  errorMoreInformationLink?: ErrorMoreInformationLink;
  extraErrors?: InputExtraError[];
}

export const DisplayFieldErrors = ({
  fieldErrors,
  oneField,
  fields,
  testId,
  errorMoreInformationLink,
  extraErrors = [],
}: DisplayFieldErrorsProps) => (
  <NewErrorWrapper data-testid={`${testId}-error-wrapper`}>
    {fields ? (
      // it's formGenerator
      <>
        <ListUL oneField={Boolean(oneField)}>
          {[
            ...fields.map((field) => ({
              name: field.props.name,
              label: (field.props as WalInputProps).label || '',
              errorMoreInformationLink:
                (field.props as WalInputProps)?.errorMoreInformationLink || undefined,
            })),
            ...extraErrors,
          ]
            ?.map(({ name, label, errorMoreInformationLink: inputErrorMoreInformationLink }) =>
              name && has(fieldErrors, name) ? (
                <React.Fragment key={name}>
                  <li>
                    <DisplayFieldError
                      fieldError={get(fieldErrors, name) as ErrorOption}
                      oneField={Boolean(oneField)}
                      label={label}
                      errorMoreInformationLink={inputErrorMoreInformationLink}
                    />
                  </li>
                  {typeof inputErrorMoreInformationLink === 'string' && (
                    <ErrorLink href={inputErrorMoreInformationLink} />
                  )}
                </React.Fragment>
              ) : undefined
            )
            .filter((f) => f !== undefined)}
        </ListUL>
        {typeof errorMoreInformationLink === 'string' && (
          <ErrorLink href={errorMoreInformationLink} />
        )}
      </>
    ) : (
      // It's a single input
      <p>
        <DisplayFieldError
          fieldError={fieldErrors}
          oneField={Boolean(oneField)}
          label={typeof oneField !== 'boolean' ? oneField.label || '' : ''}
          errorMoreInformationLink={errorMoreInformationLink}
        />
        {typeof errorMoreInformationLink === 'string' && (
          <ErrorLink href={errorMoreInformationLink} />
        )}
      </p>
    )}
  </NewErrorWrapper>
);

/**
 * Returns an input component.
 *
 * Set the `rows` prop to return a textarea.
 *
 * Default errors messages can be overwritten by passing an InputValidator object
 * and defining the message property, e.g. instead of `required={true}`
 * use `required={{value: true, message: 'Custom error message'}}`.
 */
export const WalInput = ({
  name,
  id,
  type = 'text',
  label,
  placeholder = '',
  size = 'medium',
  readonly = false,
  allowArrowScrolling,
  viewMode = false,
  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  rows,
  className,
  defaultValue,
  inputRef,
  onClick,
  onChange,
  onInput,
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onBlur,
  onFocus,
  validate,
  monacoEditorOnValidate,
  standardValidation,
  onPaste,
  noMargin,
  hideLabel,
  fakeInput,
  fakeInputMinHeight,
  children,
  labelExtensionText,
  propagateClickEvent,
  noHover,
  noBorderRadius,
  noLabelOverflow,
  transparent,
  labelAbove,
  description,
  dataTestId,
  ariaControls,
  fontFamily,
  valueAsNumber,
  secret,
  autoComplete = 'off',
  tabIndex,
  hideError,
  errorMoreInformationLink,
  hideOutline,
  autoCompleteOptions,
  isMonacoEditor,
  monacoEditorFileExtension,
  focusOnInputState,
  yamlSchema,
  monacoOptions,
  deleteAction,
}: WalInputProps) => {
  // Component state
  const [labelExtension, setLabelExtension] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [newValidation, setNewValidation] = useState<
    Validate<string, any> | Record<string, Validate<string, any>> | undefined
  >(validate);

  const minHeight = rows ? rows * 30 : 30;
  const [height, setHeight] = useState(minHeight);
  const isMultiLineInput = Boolean(rows);

  const [focusOnInput] = focusOnInputState || [];

  const monacoWrapperRef = useRef<HTMLDivElement>(null);

  // Form
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const {
    ref,
    onChange: formOnChange,
    onBlur: formOnBlur,
    ...formRest
  } = register(name, {
    validate: {
      ...newValidation,
      ...validate,
    },
    required: required && `${!label ? 'This field' : label} is required`,
    maxLength: maxLength && {
      value: maxLength,
      message: `Cannot exceed ${maxLength} characters`,
    },
    minLength: minLength && {
      value: minLength,
      message: `Must be at least ${minLength} characters`,
    },
    max: max && { value: max, message: `Max is ${max}` },
    min: min && { value: min, message: `Min is ${min}` },
    /* Pattern validation can only be active when valueAsNumber is not true. See node_modules/react-hook-form/dist/types/validator.d.ts */
    ...(valueAsNumber === true
      ? { valueAsNumber }
      : {
          /**
           * Pattern is required to be passed to this component in value & message format.
           * If we can't be sure that the message is defined (e.g. DynamicForm), just pass empty string
           */
          pattern: pattern && {
            ...pattern,
            message: pattern.message || `Must match pattern ${pattern.value}`,
          },
        }),
  });

  const fieldError = get(errors, name) as ErrorOption;
  const hasValue = Boolean(watch(name));

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  useEffect(() => {
    if (name) {
      setInputValue(watch(name));
    }
  }, [name, watch]);

  useEffect(() => {
    if (!inputValue) {
      setLabelExtension(null);
    } else {
      setLabelExtension(`${label}`);
    }
  }, [inputValue, label]);

  useEffect(() => {
    if (defaultValue && name) {
      setLabelExtension(`${label}`);
      setValue(name, defaultValue);
    }
  }, [defaultValue, label, name, setValue]);

  useEffect(() => {
    if (readonly && fieldError) {
      clearErrors(name);
    }
  }, [readonly, clearErrors, name, fieldError]);

  useEffect(() => {
    const newValidations: Record<string, Validate<string, string>> = {};

    const mapErrors = (validations: Record<string, GranularRegexMatch>, valType: string) => {
      Object.entries(validations).forEach(([key, r]) => {
        newValidations[`${valType}-${key}`] = (value: string) => {
          if (!required && !value) {
            return true;
          } else {
            if (r.negativeMatch) {
              return r.regex.test(value) ? r.transkey : true;
            } else {
              return r.regex.test(value) || r.transkey;
            }
          }
        };
      });
    };

    for (const val of standardValidation || []) {
      const found = validationMessages(label).find((v) => v.type === val.type);

      if (val.type === 'id') {
        Object.entries(ID_VALIDATIONS).forEach(([key, r]) => {
          newValidations[`id${key}`] = (value: string) => {
            if (value?.length > 0) {
              return r.negativeMatch
                ? val.exceptions?.includes(value) || (r.regex.test(value) ? r.transkey : true)
                : r.regex.test(value) || val.exceptions?.includes(value) || r.transkey;
            }
          };
        });
      } else if (val.type === 'resourceId') {
        newValidations.resourceId = (value: string) =>
          value
            ? RESOURCE_ID_REGEXP.test(value) ||
              val.exceptions?.includes(value) ||
              uiTranslations.RESOURCE_ID_INSTRUCTIONS_NEW_ERRORS
            : true;
      } else if (val.type === 'existingId') {
        newValidations[val.type] = (value: string) =>
          !val.ids.includes(value) ||
          val.customMessage ||
          (label ? `${label} already exists` : 'ID already exists');
      } else if (val.type === 'name') {
        mapErrors(NAME_VALIDATIONS, val.type);
      } else if (val.type === 'sub-path') {
        mapErrors(SUB_PATH_VALIDATIONS, val.type);
      } else if (val.type === 'idStartWithAlphabetic') {
        mapErrors(ID_START_WITH_APLHABETIC_VALIDATIONS, val.type);
      } else if (val.type === 'path') {
        mapErrors(PATH_VALIDATIONS, val.type);
      } else if (found) {
        newValidations[val.type] = found.message;
      }
    }

    setNewValidation(newValidations);
  }, [
    standardValidation,
    label,
    uiTranslations.RESOURCE_ID_INSTRUCTIONS,
    uiTranslations.RESOURCE_ID_INSTRUCTIONS_NEW_ERRORS,
    required,
  ]);

  const handleInputFieldFocus = () => {
    if (labelExtensionText) {
      setLabelExtension(`${label}${labelExtensionText}`);
    } else {
      setLabelExtension(`${label}`);
    }
  };

  const handleInputFieldBlur = (evt: any) => {
    if (evt.target.value === '' || evt.target.textContent === '') {
      setLabelExtension(null);
    } else {
      setLabelExtension(`${label}`);
    }
  };

  const handleFakeInputKeyDown = (event: KeyboardEvent) => {
    if (onKeyDown) {
      if (
        maxLength &&
        event.currentTarget.textContent &&
        event.currentTarget.textContent?.length > maxLength
      ) {
        event.preventDefault();
      }
      return onKeyDown(event);
    } else {
      return null;
    }
  };

  const renderSecretIcon = () => (
    <InputIcon>
      <Tooltip
        tabIndex={-1}
        width={300}
        triggerComponent={<Icon name={'padlock'} tabIndex={0} />}
        text={uiTranslations.SECRETS_INFO_TEXT}
        position={'left'}
      />
    </InputIcon>
  );

  const renderDeleteIcon = () =>
    deleteAction && (
      <InputIcon>
        <Icon
          pointer
          name={'delete'}
          tabIndex={0}
          onClick={deleteAction}
          onKeyDown={deleteAction}
        />
      </InputIcon>
    );

  useEffect(() => {
    if (!monacoWrapperRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry?.contentRect?.height) {
        setHeight(entry?.contentRect?.height);
      }
    });
    resizeObserver.observe(monacoWrapperRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <InputField
      data-testid={dataTestId || 'input'}
      hideLabel={Boolean(hideLabel)}
      onFocus={handleInputFieldFocus}
      onBlur={handleInputFieldBlur}
      className={`${className} input-wrapper`}
      noLabelOverflow={Boolean(noLabelOverflow)}
      noMargin={Boolean(noMargin)}>
      {label && (!placeholder || labelAbove) && (
        <InputLabel
          size={size}
          hideLabel={Boolean(hideLabel)}
          htmlFor={id || name}
          valueSet={Boolean(hasValue)}
          labelExtensionSet={Boolean(labelExtension || hasValue || labelAbove || viewMode)}
          readOnly={readonly}>
          {`${labelExtension || label}${required ? '*' : ''}`}
        </InputLabel>
      )}
      {viewMode ? (
        <ViewModeText data-testid={'view-mode-text'}>
          {secret ? (
            '••••••••••'
          ) : defaultValue && defaultValue?.length > 50 ? (
            <CopyText text={defaultValue} showText />
          ) : (
            defaultValue || '-'
          )}
        </ViewModeText>
      ) : rows && !isMonacoEditor ? (
        <>
          <Textarea
            {...formRest}
            hideOutline={hideOutline}
            minHeight={minHeight}
            isExpandableTextareasEnabled
            autoComplete={autoComplete}
            data-testid={`${name}-input-element`}
            invalid={Boolean(name && fieldError)}
            ref={(e) => {
              // Sharing ref usage: https://react-hook-form.com/faqs#Howtosharerefusage
              if (inputRef) {
                inputRef.current = e;
              }
              ref(e);
            }}
            className={'input-element'}
            inputSize={size}
            name={name}
            id={id || name}
            rows={rows}
            readOnly={readonly}
            allowArrowScrolling={allowArrowScrolling}
            disabled={!allowArrowScrolling && readonly}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={(event) => {
              formOnChange(event);
              if (onChange) {
                onChange(event.target.value);
              }
            }}
            onBlur={(event) => {
              formOnBlur(event);

              if (onBlur) {
                onBlur(event.target.value);
              }
            }}
            onClick={(event) => (onClick ? onClick(event) : event.stopPropagation())}
            onFocus={(event) => (onFocus ? onFocus(event.target.value) : null)}
            noBorderRadius={noBorderRadius}
            transparent={transparent}
            aria-controls={ariaControls}
            fontFamily={fontFamily}
            iconRight={secret}
          />
          {secret && renderSecretIcon()}
          {renderDeleteIcon()}
        </>
      ) : isMonacoEditor ? (
        <>
          <MonacoEditorWrapper
            ref={monacoWrapperRef}
            resizeable={isMultiLineInput}
            minHeight={minHeight}
            size={size}>
            <BasicEditor
              monacoOptions={monacoOptions}
              customLanguage={
                autoCompleteOptions ? autoCompleteOptions.customLanguage || 'variables' : undefined
              }
              placeholders={
                autoCompleteOptions ? JSON.stringify([autoCompleteOptions.lookupObject]) : undefined
              }
              fileExtension={monacoEditorFileExtension}
              tabIndex={tabIndex}
              height={rem(height)}
              inputSize={size}
              inputRef={inputRef}
              value={watch(name) ? watch(name).toString() : ''}
              onChange={(val) => {
                setValue(name, val && type === 'number' && isNumeric(val) ? Number(val) : val, {
                  shouldDirty: true,
                });
                if (onChange && val) {
                  onChange(val);
                }
              }}
              data-testid={`${name}-input-element`}
              onFocus={(value: string | null) => (onFocus ? onFocus(value) : null)}
              onBlur={(value: string | null) => onBlur && onBlur(value ?? '')}
              onKeyDown={(event: KeyboardEvent) => {
                if (onKeyDown) {
                  onKeyDown(event);
                }
              }}
              isMultiLineInput={isMultiLineInput}
              onKeyUp={(event: KeyboardEvent) => onKeyUp && onKeyUp(event)}
              fontSize={size === 'large' ? units.fontSize.base : units.fontSize.sm}
              readOnly={readonly ?? defaultValue}
              autofocus={focusOnInput}
              onValidate={monacoEditorOnValidate}
              yamlSchema={yamlSchema}
            />
          </MonacoEditorWrapper>
          {secret && renderSecretIcon()}
          {renderDeleteIcon()}
          <HiddenInput
            {...register(name)}
            defaultValue={watch(name)}
            readOnly={readonly}
            id={name}
            data-testid={`${name}-input-element`}
          />
        </>
      ) : fakeInput ? (
        <FakeInput
          fakeInputMinHeight={fakeInputMinHeight}
          hideOutline={hideOutline}
          tabIndex={tabIndex}
          data-testid={`${name}-input-element`}
          className={'input-element'}
          fakeInput={fakeInput}
          ref={inputRef}
          invalid={Boolean(errors && name && fieldError)}
          inputSize={size}
          readOnly={readonly}
          placeholder={placeholder}
          onPaste={(event) => onPaste?.(event)}
          onInput={(event) => (onInput ? onInput(event.currentTarget) : null)}
          onBlur={(event) => {
            if (onBlur && event.target.textContent) {
              onBlur(event.target.textContent);
            }
          }}
          onClick={(event) => (onClick ? onClick(event) : event.stopPropagation())}
          onFocus={(event) => (onFocus ? onFocus(event.target.textContent) : null)}
          onMouseDown={(event) => (onMouseDown ? onMouseDown(event) : null)}
          noBorderRadius={noBorderRadius}
          transparent={transparent}
          aria-controls={ariaControls}
          fontFamily={fontFamily}
          onKeyDown={handleFakeInputKeyDown}>
          {children}
        </FakeInput>
      ) : (
        <>
          <Input
            hideOutline={hideOutline}
            {...formRest}
            size={1}
            tabIndex={tabIndex || 0}
            autoComplete={autoComplete}
            data-testid={`${name}-input-element`}
            className={'input-element'}
            invalid={Boolean(errors && name && fieldError)}
            ref={(e) => {
              // Sharing ref usage: https://react-hook-form.com/faqs#Howtosharerefusage
              if (inputRef) {
                inputRef.current = e;
              }
              ref(e);
            }}
            inputSize={size}
            name={name}
            id={id || name}
            key={id}
            type={type}
            min={min}
            max={max}
            noHover={noHover}
            placeholder={placeholder}
            readOnly={readonly}
            allowArrowScrolling={allowArrowScrolling}
            disabled={!allowArrowScrolling && readonly}
            defaultValue={defaultValue}
            onChange={(event) => {
              formOnChange(event);
              if (onChange) {
                onChange(event.target.value);
              }
            }}
            onBlur={(event) => {
              formOnBlur(event);
              if (onBlur) {
                onBlur(event.target.value);
              }
            }}
            onPaste={(event) => onPaste?.(event)}
            onClick={(event) =>
              onClick ? onClick(event) : propagateClickEvent ? null : event.stopPropagation()
            }
            onFocus={(event) => (onFocus ? onFocus(event.target.value) : null)}
            onKeyDown={(event) => (onKeyDown ? onKeyDown(event) : null)}
            onMouseDown={(event) => (onMouseDown ? onMouseDown(event) : null)}
            noBorderRadius={noBorderRadius}
            transparent={transparent}
            aria-controls={ariaControls}
            fontFamily={fontFamily}
            iconRight={secret}
          />
          {secret && renderSecretIcon()}
          {renderDeleteIcon()}
        </>
      )}
      {!hideError && fieldError && !readonly && !viewMode && (
        <DisplayFieldErrors
          testId={dataTestId}
          fieldErrors={fieldError}
          oneField={{ label }}
          errorMoreInformationLink={errorMoreInformationLink}
        />
      )}
      {description && !viewMode && <InputDescription>{description}</InputDescription>}
    </InputField>
  );
};
